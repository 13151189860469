import Checkbox from "@mui/material/Checkbox";
import {
  Avatar,
  Badge,
  Box,

  Button,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  List,
  ListItemButton,
  MenuItem,
  Popover,
  PopoverOrigin,
  Select,
  Stack,
  TextareaAutosize,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import Iconify from "../../../app/iconify";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";

import {
  INV_ITEM_RATE,
  INV_ITEM_RATE_NAME,
  INV_ITEM_RATE_RANGE,
} from "../../../app/utils/models/modelConstants/modelConstants";
import { useCurrentBreakpoint, useResponsiveFontSize } from "../../../app/hooks/use-responsive-font";

import { SelectedItem } from "../../../app/interfaces/SelectedItem";
import { makeStyles } from "@mui/styles";
import { firelikeId } from "../../../app/utils/models/checkers/firelikeid";
import { enqueueSnackbar } from "notistack";
import { sleep } from "../../../app/utils/Nifty/nifty";
import { use } from "i18next";
import { fCurrency } from "../../reports/sales/components/format-number";
import { green, red, yellow } from "@mui/material/colors";

import { ListItem, ListItemText } from '@mui/material';
import { DragHandle } from "@mui/icons-material";
import RowDragHandle from "./DragHandle";
import { FormatToLocalCurrency } from "../../../app/utils/currency/formatToLocalCurrency";
import { OrganizationData } from "../../../app/interfaces/organizationData";

// ----------------------------------------------------------------------

type Props = {
  item: SelectedItem;
  orgData: OrganizationData;
  index: number;
  isSalesInvoice: boolean;
  availability: number | null;
  loadingAvailability: boolean;

  handleInsertItem: (index: number) => void;
  handleInsertSpecial: (itemType: string, index: number, itemData: any, qty: number) => void;

  // handleInsertSubtotal: (index: number) => void;
  // handleInsertLabel: (index: number) => void;
  // handleInsertDamageWaiver: (index: number) => void;
  // handleInsertFlatFee: (index: number) => void;

  handleUpdateSelectedItem: (index: number, item: any) => void;
  onChangeItem: (item: SelectedItem) => void;
  onDeleteRow: (index: number) => void;
};

const useStyles = makeStyles({
  input: {
    '& input[type=number]': {
        '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0
    }
  },
});

const RentalRow = React.memo(function RentalRow({
  item,
  orgData,
  index,
  isSalesInvoice,
  availability,
  loadingAvailability,
  handleInsertItem,
  handleInsertSpecial,
  handleUpdateSelectedItem,
  onChangeItem,
  onDeleteRow,
}: Props) {

  console.log("RentalRow Rendered");
  // Text Field Refs
  const descriptionRef = useRef<HTMLInputElement>(null);
  const quantityRef = useRef<HTMLInputElement>(null);
  const durationRef = useRef<HTMLInputElement>(null);

  const responsiveFontSize = useResponsiveFontSize();
  const classes = useStyles();

  const theme = useTheme();
  const { currentBreakpoint, isSMAndDown, isMDAndDown } = useCurrentBreakpoint();

  const [itemName, setItemName] = useState(item?.name || "");
  const [itemDescription, setItemDescription] = useState(item?.selectedDescription || "");
  const [duration, setDuration] = useState<any>(item?.selectedDuration || 0);
  const [taxable, setTaxable] = useState(item?.selectedTaxable || false);
  const [quantity, setQuantity] = useState(item?.selectedQty || 1);

  const [rate, setRate] = useState(
    item?.selectedRateMeta || {
      rate: 0,
      rateName: "",
      rateRange: "",
    }
  );
  const [customAmt, setCustomAmt] = useState<any>(null);
  const [customRange, setCustomRange] = useState<any>("day");

  const [total, setTotal] = useState(item?.rowTotal || 0);

  const updateItem = () => {
    item.selectedDescription = itemDescription;
    item.selectedQty = quantity;
    item.selectedDuration = duration;
    item.selectedTaxable = taxable;
    item.rowTotal = total;
    item.selectedRateMeta = rate;

    handleUpdateSelectedItem(item.listIndex, item);
  };

  useEffect(() => {
    updateItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [total]);

  // Calculate the total based on quantity, rate, and duration
  const calculateTotal = useCallback(() => {
    if (item.type === "sale") {
      setTotal(quantity * rate.rate);
    } else {
      setTotal(rate.rate * duration * quantity);
    }
  }, [quantity, duration, rate.rate, item.type]);

  useEffect(() => {
    calculateTotal();
  }, [calculateTotal]);

  const handleDurationChange = (event: any) => {
    const value = event.target.value;
    setDuration(value === "" ? "" : Number(value));
  };

  const configureDurationOpts = (type: string) => {
    if (type === "sale") {
      // return <Option value={"none"}>None</Option>;
    } else {
      const opts = [
        { label: "Per hour", value: "hour" },
        { label: "Per day", value: "day" },
        { label: "Per week", value: "week" },
        { label: "Per month", value: "month" },
      ];
      return opts;
    }
  };
  const handleCustomRate = () => {
    // create custom rate obj with unique name
    const rate = {
      id: firelikeId(),
      [INV_ITEM_RATE]: customAmt,
      [INV_ITEM_RATE_RANGE]: customRange,
      [INV_ITEM_RATE_NAME]: `Custom ${item.rates ? item.rates.length + 1 : 1}`,
    };

    item.rates.push(rate);
    item.selectedRateMeta = rate;
    item.selectedRate = rate[INV_ITEM_RATE_NAME];

    setRate(rate);
    setCustomAmt(0);
    setCustomRange("day");
    handleClose();
    calculateTotal();
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    updateItem();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxable]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const NumberCircle = React.memo(function NumberCircle({ available }: { available: number | null }) {

    if (loadingAvailability) {
      return (
        <Tooltip title={"Loading availability data"}>
          <Typography fontWeight={"bold"} fontSize={responsiveFontSize - 4}>
            {`...`}
          </Typography>
        </Tooltip>
      );
    }

    if (available === undefined || available === null || isNaN(available)) {
      return (
        <Tooltip title={"No availability data"}>
          <Typography fontWeight={"bold"} fontSize={responsiveFontSize - 4}>
            {`...`}
          </Typography>
        </Tooltip>
      );
    }

    const adjusted = available - quantity;

    let backgroundColor;
    if (adjusted < 0) {
      backgroundColor = red[500];
    } else if (adjusted === 0) {
      backgroundColor = yellow[700];
    } else {
      backgroundColor = green[500];
    }

    let finalNumber = adjusted.toString();
    if (adjusted > 999) {
      finalNumber = "+1k";
    }

    return (
      <Tooltip title={`Available: ${adjusted}`}>
        <Typography
          fontWeight={"bold"}
          fontSize={10}
          sx={{
            color: backgroundColor,
          }}
        >
          {finalNumber}
        </Typography>
      </Tooltip>
    );
  });
  // const NumberCircle = ({ available }: { available: number | null }) => {

  //   if (loadingAvailability) {
  //     return (
  //       <Tooltip title={"Loading availability data"}>
  //         <Typography fontWeight={"bold"} fontSize={responsiveFontSize - 4}>
  //           {`...`}
  //         </Typography>
  //       </Tooltip>
  //     );
  //   }

  //   if (available === undefined || available === null || isNaN(available)) {
  //     return (
  //       <Tooltip title={"No availability data"}>
  //         <Typography fontWeight={"bold"} fontSize={responsiveFontSize - 4}>
  //           {`...`}
  //         </Typography>
  //       </Tooltip>
  //     );
  //   }

  //   const adjusted = available - quantity;

  //   let backgroundColor;
  //   if (adjusted < 0) {
  //     backgroundColor = red[500];
  //   } else if (adjusted === 0) {
  //     backgroundColor = yellow[700];
  //   } else {
  //     backgroundColor = green[500];
  //   }

  //   let finalNumber = adjusted.toString();
  //   if (adjusted > 999) {
  //     finalNumber = "+1k";
  //   }

  //   return (
  //     <Tooltip title={`Available: ${adjusted}`}>
  //       <Typography
  //         fontWeight={"bold"}
  //         fontSize={10}
  //         sx={{
  //           color: backgroundColor,
  //         }}
  //       >
  //         {finalNumber}
  //       </Typography>
  //     </Tooltip>
  //   );
  // };

  const memoizedMenuItems = useMemo(
    () => [
      {
        text: "Insert Item",
        onClick: () => handleInsertItem(index),
      },
      {
        text: "Insert Subtotal",
        onClick: () => handleInsertSpecial("subtotal", index, null, 1),
      },
      // {
                //   text: "Insert Label",
                //   onClick: () => handleInsertLabel(index),
                // },
                // {
                //   text: "Insert Damage Waiver",
                //   onClick: onChangeItem,
                // },
      {
        text: "Delete",
        onClick: () => onDeleteRow(item.listIndex),
      },
    ],
    [index, item.listIndex, handleInsertItem, handleInsertSpecial, onDeleteRow]
  );

  return (
    <Box>
      <Grid sx={{ backgroundColor: theme.palette.background.default }} container spacing={{ xs: 2, lg: 1, xl: 2 }} alignItems="top" columns={24}>
        {/* //MARK: - Drag Handle */}
        <Grid item xs={24} sm={12} lg={6}>
          <Stack direction={"row"} gap={1} alignItems={"center"}>

            <RowDragHandle
              triggerType="rightClick"
              index={index}
              menuItems={memoizedMenuItems}
            />

            <TextField
              onClick={() => { onChangeItem(item)}}
              InputProps={{
                inputComponent: TextareaAutosize,
                inputProps: {
                  minRows: 1,
                  maxRows: isMDAndDown ? 2 : 3,
                  style: { resize: "none", fontSize: responsiveFontSize },
                },
              }}
              inputProps={{
                sx: {
                  fontSize: responsiveFontSize,
                  readOnly: true,
                },
              }}
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "#000000",
                },
              }}
              InputLabelProps={{ style: { fontSize: responsiveFontSize } }}
              size="small"
              label="Item Name"
              variant="outlined"
              fullWidth
              value={itemName}
            />
          </Stack>
        </Grid>

        {/* //MARK: - Description */}
        <Grid item xs={24} sm={12} lg={7} xl={8}>
          <TextField
            inputProps={{ style: { fontSize: responsiveFontSize } }}
            InputProps={{
              inputComponent: TextareaAutosize,
              inputProps: {
                minRows: 1,
                maxRows: isMDAndDown ? 4 : 8,
                style: { resize: "none", fontSize: responsiveFontSize },
              },
            }}
            inputRef={descriptionRef}
            InputLabelProps={{ style: { fontSize: responsiveFontSize } }}
            size="small"
            label="Description"
            variant="outlined"
            fullWidth
            value={itemDescription}
            onChange={(e) => setItemDescription(e.target.value)}
            onBlur={updateItem}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (descriptionRef.current) {
                  descriptionRef.current.blur();
                }
              }
            }}
          />
        </Grid>

        {/* //MARK: - Quantity */}
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <Stack direction={"row"} alignItems={"center"}>
            <TextField
              inputRef={quantityRef}
              className={classes.input}
              InputLabelProps={{ style: { fontSize: responsiveFontSize } }}
              size="small"
              label="Quantity"
              type="number"
              variant="outlined"
              fullWidth
              InputProps={{
                inputProps: {
                  min: 1,
                  style: { fontSize: responsiveFontSize },
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <NumberCircle available={availability} />
                  </InputAdornment>
                ),
              }}
              placeholder="0"
              value={quantity === 0 ? "" : quantity}
              onChange={(e) => setQuantity(Number(e.target.value))}
              onBlur={updateItem}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (quantityRef.current) {
                    quantityRef.current.blur();
                  }
                }
              }}
            />
          </Stack>
        </Grid>

        {/* //MARK: - Rate */}
        <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
          <TextField
            disabled={true}
            inputProps={{
              sx: {
                fontSize: responsiveFontSize,
                readOnly: true,
              },
            }}
            InputProps={{
              endAdornment: (
                <IconButton size="small" onClick={handleClick}>
                  <Iconify icon="carbon:edit" />
                </IconButton>
              ),
            }}
            sx={{
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "#000000",
              },
            }}
            InputLabelProps={{ style: { fontSize: responsiveFontSize } }}
            size="small"
            label="Rate"
            variant="outlined"
            fullWidth
            value={item.type === "sale" ? `$${rate.rate}` : `$${rate.rate} / ${rate.rateRange}`}
          />
        </Grid>

        {/* //MARK: - Duration */}
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <TextField
            inputRef={durationRef}
            className={classes.input}
            InputLabelProps={{ style: { fontSize: responsiveFontSize } }}
            size="small"
            label="Duration"
            type="number"
            variant="outlined"
            fullWidth
            InputProps={{
              inputProps: {
                min: 0,
                style: { fontSize: responsiveFontSize },
              },
            }}
            placeholder={isSalesInvoice ? "--" : "0"}
            value={duration}
            disabled={isSalesInvoice}
            onChange={handleDurationChange}
            onBlur={updateItem}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (durationRef.current) {
                  durationRef.current.blur();
                }
              }
            }}
          />
        </Grid>

        {/* //MARK: - TAXABLE */}
        <Grid item xs={12} sm={6} md={4} lg={2}>
          <FormControlLabel
            control={
              <Checkbox
                inputProps={{ style: { fontSize: responsiveFontSize } }}
                checked={taxable}
                onChange={(e) => {
                  setTaxable(e.target.checked);
                }}
              />
            }
            label={<Typography fontSize={responsiveFontSize}>{isMDAndDown ? "Taxable" : "Taxed"}</Typography>}
          />
        </Grid>

        {/* //MARK: - TOTAL */}
        <Grid item xs={12} sm={12} md={4} lg={1}>
          <div style={{ width: "100%", textAlign: "left", paddingRight: "16px" }}>
            {useMediaQuery(theme.breakpoints.up("lg")) && <Typography fontSize={responsiveFontSize}>{FormatToLocalCurrency(total, orgData) || "$0.00"}</Typography>}
            {useMediaQuery(theme.breakpoints.down("lg")) && <Typography fontSize={responsiveFontSize}>Total: {FormatToLocalCurrency(total, orgData)}</Typography>}
          </div>
        </Grid>

        {/* //MARK: - ACTIONS */}
        <Grid item alignContent={"start"} xs={12} sm={12} md={4} lg={1}>
          <Box display="flex" justifyContent={isMDAndDown ? "flex-end" : "flex-start"} width="100%">
            <Button size="small" color="error" startIcon={<Iconify icon="solar:trash-bin-trash-bold" />} onClick={() => onDeleteRow(item.listIndex)} />
          </Box>
        </Grid>
      </Grid>

      {/* //MARK: - RATE POPOVER */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        slotProps={{
          paper: {
            sx: { width: "300px" },
          },
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box sx={{ p: 1 }}>
          <List>
            {item &&
              item.rates &&
              item.rates.map((r: any) => (
                <ListItemButton
                  key={r.id}
                  onClick={() => {
                    setRate(r);
                    handleClose();
                  }}
                >
                  <Typography variant="body2">{`${r.rateName} (${r.rate}${item.type === "sale" ? "" : ` per ${r.rateRange}`})`}</Typography>
                </ListItemButton>
              ))}
          </List>

          {/* <Divider style={{ margin: "4px 0" }} /> */}
          <Typography sx={{ mt: 1 }} variant="subtitle2">
            CUSTOM RATE
          </Typography>
          <Box sx={{ mt: 1 }}>
            <Stack direction={"row"} gap={1}>
              <TextField
                className={classes.input}
                size="small"
                label="Amount"
                type="number"
                placeholder="0.00"
                value={customAmt === 0 ? "" : customAmt}
                onChange={(e) => setCustomAmt(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Box sx={{ typography: "subtitle2", color: "text.disabled" }}>$</Box>
                    </InputAdornment>
                  ),
                  inputProps: { min: 0 },
                }}
                style={{ width: "125px" }}
              />

              {
                // Only show duration for rentals
                item.type !== "sale" && (
                  <FormControl style={{ width: "140px" }}>
                    <InputLabel>Duration</InputLabel>
                    <Select
                      onChange={(event) => {
                        setCustomRange(event.target.value);
                      }}
                      value={customRange}
                      size="small"
                    >
                      {configureDurationOpts(item.type)?.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )
              }

              <IconButton
                onClick={() => {
                  if (customAmt) {
                    handleCustomRate();
                  } else {
                    enqueueSnackbar("Please enter a rate amount", {
                      variant: "error",
                    });
                  }
                }}
              >
                <Iconify icon="material-symbols:add-circle" color={theme.palette.success.dark} />
              </IconButton>
            </Stack>
          </Box>
        </Box>
      </Popover>
    </Box>
  );
});


export default RentalRow;
