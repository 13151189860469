import { useEffect, useMemo, useState } from "react";
import "../../App.less";
import { Affix, Button, Modal, PageHeader, Space } from "antd";
import {
  ROUTE_CREATE_INVOICE,
  ROUTE_EDIT_INVOICE,
  ROUTE_RECEIVE_PAYMENT_ROOT,
} from "../../app/routes";
import { authSelector, refreshOrgState } from "../auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useFirestore } from "react-redux-firebase";
import { invoices as invoicesQuerier } from "./../../app/services/firestore/queries/invoiceQueries";

import InternalNotesDrawer from "./components/InternalNotesDrawer";
import InvoiceEmailDrawer from "./components/InvoiceEmailDrawer";
import Menu from "@mui/material/Menu";

import { Box, Dialog, MenuItem, Tooltip } from "@mui/material";

import { i18n } from "../../i18n";
import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
import { useFirestoreWrite } from "../../app/services/hooks/fetch/useFirestoreWrite";
import { getColumns } from "./invoices-datagrid-columns";
import { ConfigureDuplicateInvoice } from "../../app/utils/models/configure/configureDuplicateInvoice";
import { INVOICES_COLLECTION } from "../../app/utils/models/collections/collectionConstants";
import { sleep } from "../../app/utils/Nifty/nifty";
import notificationError from "../../app/system-components/toasters/notificationError";
import { WarningFilled } from "@ant-design/icons";
import { COLOR_RED_0 } from "../../app/ui/colorConstants";
import notificationConfirm from "../../app/system-components/toasters/notificationConfirm";
import { CustomToolbar } from "./invoices-data-grid-toolbar";
import { useGetTableRowInvoicesForRangeQuery } from "../../app/services/api";
import { useInvoiceNumberListener } from "../../app/services/hooks/useInvoiceNumberListener";
import SendEmailTemplateView from "./send-email-template-view";
import { enqueueSnackbar } from "notistack";
import { checkSubscriptionLevel } from "../../app/interfaces/features-set-permissions";


const Invoices = ({ history }) => {

  const { userData, orgData, fsOrgPrefix } = useSelector(authSelector);
  const { nextInvoiceNumber } = useInvoiceNumberListener();

  const firestore = useFirestore();
  const dispatch = useDispatch();

  const [selectedRows, setSelectedRows] = useState([]);

  const [currentDates, setCurrentDates] = useState([
    null,
    null,
  ]);

  // Set initial values.
  useEffect(() => {

    const storedDateRange = sessionStorage.getItem('selectedDateRange');

    // Check if a date range was previously stored
    if (storedDateRange) {
      const parsedDateRange = JSON.parse(storedDateRange);

      parsedDateRange.startDate = new Date(parsedDateRange.startDate);
      parsedDateRange.endDate = new Date(parsedDateRange.endDate);

      setCurrentDates([
        parsedDateRange.startDate,
        parsedDateRange.endDate,
      ])

    } else {
      const oneYearLater = new Date();
      oneYearLater.setFullYear(oneYearLater.getFullYear() + 1);
      const twoMonthAgo = new Date();
      twoMonthAgo.setMonth(twoMonthAgo.getMonth() - 2);

      setCurrentDates([
        twoMonthAgo,
        oneYearLater,
      ])
    }

  }, [])

  const [searchedInvoices, setSearchedInvoices] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState(null);

  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState();
  const [contextMenu, setContextMenu] = useState(null);
  const [openTemplateDialog, setOpenTemplateDialog] = useState(false);

  const openEmailTemplateDialog = () => {
    setOpenTemplateDialog(true);
  }

  const {
    data: tableRowInvoices,
    isFetching: fetchingInvoices,
    refetch: refetchInvoices,
  } = useGetTableRowInvoicesForRangeQuery({
    db: { firestore },
    orgPrefix: fsOrgPrefix,
    dateStart: currentDates[0] ?? null,
    dateEnd: currentDates[1] ?? null,
    orgTimezone: orgData?.orgTimezone,
  });

  useEffect(() => {
    if (!orgData || !firestore) {
      return;
    }

    refetchInvoices()
  }, [orgData, currentDates]);

  const apiRef = useGridApiRef();

  const { deleteDocument, newDocument, updateOrgDocument } = useFirestoreWrite();

  const [chatDrawer, setChatDrawer] = useState({
    visible: false,
    invoice: null,
  });
  const handleChatClick = (i) => {
    setChatDrawer({
      visible: true,
      invoice: i,
    });
  };

  const [emailDrawer, setEmailDrawer] = useState({
    visible: false,
    invoice: null,
  });
  const handleEmailsClick = (i) => {
    setEmailDrawer({
      visible: true,
      invoice: i,
    });
  };

  const muiInvoiceTableColumns = useMemo(
    () => getColumns(orgData, handleEmailsClick, handleChatClick),
    [orgData]
  );

  const handleDuplicate = async () => {
    setContextMenu(null);

    if (selectedRow) {
      setLoading(true);

      const dupInvoiceSnap = invoicesQuerier(
        orgData?.orgTimezone
      ).fetchInvoiceRawData({ firestore }, fsOrgPrefix, {
        id: selectedRow && selectedRow,
      });

      dupInvoiceSnap
        .then((res) => {
          console.log("res", res);

          const payload = ConfigureDuplicateInvoice(
            res,
            userData,
            nextInvoiceNumber
          );

          updateOrgDocument({
            data: {
              payload: {
                currentInvoiceCount: nextInvoiceNumber,
              },
              id: orgData && orgData.id,
            },
          })
            .then(() => {
              newDocument({
                data: { collection: INVOICES_COLLECTION, payload },
              })
                .then(async () => {
                  await sleep(2_000);
                  setLoading(false);
                  onDuplicateComplete();
                })
                .catch((err) => {
                  console.log(err);
                  setLoading(false);
                });
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
              notificationError("Something went wrong");
            });
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }
  };

  const handleDelete = () => {
    setContextMenu(null);
    Modal.confirm({
      icon: <WarningFilled style={{ color: COLOR_RED_0 }} />,
      title: "Delete invoice?",
      content:
        "This cannot be undone! Please note, deleting an invoice will remove it from your account completely and it cannot be recovered. It will immediately affect all associated reporting tied to this transaction (ie. QuickReport, Available Inentory, Routes...etc.) All linked payments will remain and it may affect your books. Be sure to compare transactions in QuickBooks to ensure proper account balancing.",
      okType: "danger",
      async onOk() {
        try {
          return await new Promise(async (resolve, reject) => {
            if (!fsOrgPrefix) {
              return notificationError(
                "Something went wrong",
                "Please try again later"
              );
            } else {
              selectedRow &&
                (await firestore
                  .collection("orgs")
                  .doc(orgData.id)
                  .collection("tableRowInvoices")
                  .doc(selectedRow)
                  .delete());
              await deleteDocument({
                data: {
                  collection: INVOICES_COLLECTION,
                  id: selectedRow && selectedRow,
                },
              });
              resolve();
              onDeleteComplete();
              notificationConfirm("Invoice deleted", "");
            }
          });
        } catch (err) {
          console.log(err);
          notificationError("Something went wrong", "Please try again later");
        }
      },
      onCancel() { },
    });
  };

  const handleReceivePayment = () => {
    const invoice = tableRowInvoices.find((invoice) => invoice.id === selectedRow);

    if (invoice) {
      const customer = invoice.customerQueryHook;
      const invoiceId = invoice.id;
      history.push({
        pathname: `${ROUTE_RECEIVE_PAYMENT_ROOT}${customer}`,
        state: { invoiceId: invoiceId },
      });
    }
  };

  const handleView = () => {
    history.push(`${ROUTE_EDIT_INVOICE}${selectedRow && selectedRow}`);
  };

  const handleDoubleClickMenu = (params, event) => {
    setSelectedRow(params.id);
    setContextMenu(
      contextMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null
    );
  };

  const handleContextMenu = (event) => {
    event.preventDefault();
    setSelectedRow(event.currentTarget.getAttribute("data-id"));
    setContextMenu(
      contextMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const onDeleteComplete = () => {
    refetchInvoices();
  };

  const onDuplicateComplete = async () => {
    dispatch(refreshOrgState({ firestore }, orgData));
  };

  const saveStateToLocalStorage = debounce((state) => {
    localStorage.setItem("CURRENT_GRID_SETTINGS", JSON.stringify(state));
  }, 500); 
  

  return (
    <div>
      <Affix>
        <PageHeader
          className="PageHeader"
          title={i18n("invoices.sales")}
          extra={[
            <div>
              <Space size={12} className="make-the-sale-header">
                {selectedRows.length > 0 && (
                  <Tooltip 
                    title={!checkSubscriptionLevel("makeTheSale.emailTemplates", orgData) ? 
                    "You do not have the required plan for sending email templates. Requires Plus Plan, you can change this in Admin/Billing" :
                     "" 
                    }
                  >
                  <Button
                    disabled={!checkSubscriptionLevel("makeTheSale.emailTemplates", orgData)}
                    type="primary"
                    onClick={openEmailTemplateDialog}
                  >
                    Send Emails
                  </Button>
                  </ Tooltip>
                )}

                <Button
                  type="primary"
                  onClick={() => {
                    history.push(ROUTE_CREATE_INVOICE);
                  }}
                >
                  {i18n("invoices.newOrder")}
                </Button>
              </Space>
            </div>,
          ]}
        />
      </Affix>
      <div className="make-the-sale-invoices">
        <div style={{ width: "100%", paddingLeft: "8px", paddingRight: "8px" }}>
          {orgData && apiRef && tableRowInvoices && (
            <Box
              sx={{ height: "100%", width: "100%", backgroundColor: "white" }}
            >
              <DataGridPro
                disableRowSelectionOnClick
                style={{ paddingLeft: "8px" }}
                autoHeight={true}
                ignoreDiacritics={true}
                apiRef={apiRef}
                pagination={true}
                loading={fetchingInvoices || loading}
                columns={muiInvoiceTableColumns}
                initialState={{
                  pagination: {
                    paginationModel: { pageSize: 25 },
                  },
                  sorting: {
                    sortModel: [{ field: "invoiceNumber", sort: "desc" }],
                  },
                }}
                slots={{
                  noResultsOverlay: () => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <h2>
                        No results found. Try expanding the date range if you
                        are not finding what you expect.
                      </h2>
                    </div>
                  ),
                  toolbar: () =>
                    CustomToolbar(apiRef, currentDates, setCurrentDates, orgData),
                }}
                onStateChange={(state) => {
                  saveStateToLocalStorage(apiRef.current.exportState());
                }}
                onRowSelectionModelChange={(params) => {
                  setSelectedRows(params);
                }}
                onRowDoubleClick={(params, event) =>
                  handleDoubleClickMenu(params, event)
                }
                slotProps={{
                  row: {
                    onContextMenu: handleContextMenu,
                    style: { cursor: "context-menu" },
                  },
                }}
                rows={tableRowInvoices}
                checkboxSelection={true}
              />
            </Box>
          )}

          <Menu
            open={contextMenu !== null}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={
              contextMenu !== null
                ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                : undefined
            }
            slotProps={{
              root: {
                onContextMenu: (e) => {
                  e.preventDefault();
                  handleClose();
                },
              },
            }}
          >
            <MenuItem onClick={handleView}>View</MenuItem>
            <MenuItem onClick={handleDuplicate}>Duplicate</MenuItem>
            {/* <MenuItem onClick={handleReceivePayment}>Receive Payment</MenuItem> */}
            <MenuItem onClick={handleDelete}>Delete</MenuItem>
          </Menu>

          <Dialog onClose={() => setOpenTemplateDialog(false)} open={openTemplateDialog}>
            <SendEmailTemplateView 
            orgData={orgData} 
            userData={userData}
            invoiceIds={selectedRows} 
            close={() => setOpenTemplateDialog(false)} 
            complete={() => {
              enqueueSnackbar("Sending Emails. Results will be sent to Dashboard notifications", {
                variant: "success",
              });
              setOpenTemplateDialog(false)
            }}/>
          </Dialog>

          {orgData && (
            <InternalNotesDrawer
              showTags={false}
              drawerView
              visible={chatDrawer.visible}
              handleCloseDrawer={setChatDrawer}
              invoice={chatDrawer.invoice}
              tags={orgData.tags}
              onComplete={() => {
                setChatDrawer({
                  visible: false,
                  item: null,
                });
              }}
            />
          )}

          <InvoiceEmailDrawer
            drawerView
            visible={emailDrawer.visible}
            handleCloseDrawer={() =>
              setEmailDrawer({
                visible: false,
                item: null,
              })
            }
            invoice={emailDrawer.invoice}
            onComplete={() => {
              setEmailDrawer({
                visible: false,
                item: null,
              });
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Invoices;

function debounce(func, wait) {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}



// import React, { Suspense, useCallback, useEffect, useMemo, useReducer } from "react";
// import "../../App.less";
// import { Affix, Button, Modal, PageHeader, Space } from "antd";
// import { ROUTE_CREATE_INVOICE, ROUTE_EDIT_INVOICE } from "../../app/routes";
// import { authSelector } from "../auth/authSlice";
// import { useSelector } from "react-redux";
// import { useFirestore } from "react-redux-firebase";
// import { invoices as invoicesQuerier } from "./../../app/services/firestore/queries/invoiceQueries";

// import Menu from "@mui/material/Menu";

// import { Box, CircularProgress, Dialog, MenuItem, Tooltip } from "@mui/material";

// import { i18n } from "../../i18n";
// import { DataGridPro, useGridApiRef } from "@mui/x-data-grid-pro";
// import { useFirestoreWrite } from "../../app/services/hooks/fetch/useFirestoreWrite";
// import { getColumns } from "./invoices-datagrid-columns";
// import { ConfigureDuplicateInvoice } from "../../app/utils/models/configure/configureDuplicateInvoice";
// import { INVOICES_COLLECTION } from "../../app/utils/models/collections/collectionConstants";
// import { sleep } from "../../app/utils/Nifty/nifty";
// import notificationError from "../../app/system-components/toasters/notificationError";
// import { WarningFilled } from "@ant-design/icons";
// import { COLOR_RED_0 } from "../../app/ui/colorConstants";
// import notificationConfirm from "../../app/system-components/toasters/notificationConfirm";
// import { CustomToolbar } from "./invoices-data-grid-toolbar";
// import { useGetInvoicesForRangeQuery, useGetTableRowInvoicesForRangeQuery } from "../../app/services/api";
// import { useInvoiceNumberListener } from "../../app/services/hooks/useInvoiceNumberListener";
// import SendEmailTemplateView from "./send-email-template-view";
// import { enqueueSnackbar } from "notistack";
// import { checkSubscriptionLevel } from "../../app/interfaces/features-set-permissions";

// const InternalNotesDrawer = React.lazy(() => import("./components/InternalNotesDrawer"));
// const InvoiceEmailDrawer = React.lazy(() => import("./components/InvoiceEmailDrawer"));

// const initialState = {
//   selectedRows: [],
//   currentDates: [
//     new Date(new Date().setMonth(new Date().getMonth() - 2)), // Two months ago
//     new Date(new Date().setFullYear(new Date().getFullYear() + 1)), // One year from now
//   ],
//   loading: false,
//   selectedRow: null,
//   contextMenu: null,
//   openTemplateDialog: false,
//   chatDrawer: { visible: false, invoice: null },
//   emailDrawer: { visible: false, invoice: null },
//   sendEmailModal: { visible: false, invoice: null },
// };

// const invoicesReducer = (state, action) => {
//   switch (action.type) {
//     case "SET_SELECTED_ROWS":
//       return { ...state, selectedRows: action.payload };
//     case "SET_CURRENT_DATES":
//       return { ...state, currentDates: action.payload };
//     case "SET_LOADING":
//       return { ...state, loading: action.payload };
//     case "SET_SELECTED_ROW":
//       return { ...state, selectedRow: action.payload };
//     case "SET_CONTEXT_MENU":
//       return { ...state, contextMenu: action.payload };
//     case "SET_OPEN_TEMPLATE_DIALOG":
//       return { ...state, openTemplateDialog: action.payload };
//     case "SET_CHAT_DRAWER":
//       return { ...state, chatDrawer: action.payload };
//     case "SET_EMAIL_DRAWER":
//       return { ...state, emailDrawer: action.payload };
//     default:
//       return state;
//   }
// };

// const useInvoicesLogic = (firestore, fsOrgPrefix, orgData, userData, nextInvoiceNumber) => {
//   const [state, dispatch] = useReducer(invoicesReducer, initialState);
//   const { deleteDocument, newDocument, updateOrgDocument } = useFirestoreWrite();

//   useEffect(() => {
//     const storedDateRange = sessionStorage.getItem("selectedDateRange");

//     if (storedDateRange) {
//       try {
//         const parsedDateRange = JSON.parse(storedDateRange);
//         const startDate = new Date(parsedDateRange.startDate);
//         const endDate = new Date(parsedDateRange.endDate);

//         if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
//           throw new Error("Invalid date");
//         }

//         dispatch({
//           type: "SET_CURRENT_DATES",
//           payload: [startDate, endDate],
//         });
//       } catch (error) {
//         console.error("Error parsing stored date range:", error);
//         // Fall back to default date range
//         const twoMonthAgo = new Date(new Date().setMonth(new Date().getMonth() - 2));
//         const oneYearLater = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
//         dispatch({
//           type: "SET_CURRENT_DATES",
//           payload: [twoMonthAgo, oneYearLater],
//         });
//       }
//     }
//   }, []);

//   const {
//     data: tableRowInvoices,
//     isFetching: fetchingInvoices,
//     refetch: refetchInvoices,
//   } = useGetTableRowInvoicesForRangeQuery({
//     db: { firestore },
//     orgPrefix: fsOrgPrefix,
//     dateStart: state.currentDates[0] instanceof Date ? state.currentDates[0] : null,
//     dateEnd: state.currentDates[1] instanceof Date ? state.currentDates[1] : null,
//     orgTimezone: orgData?.orgTimezone || "UTC",
//   });

//   const handleDuplicate = useCallback(async () => {
//     dispatch({ type: "SET_CONTEXT_MENU", payload: null });
//     if (state.selectedRow) {
//       dispatch({ type: "SET_LOADING", payload: true });
//       try {
//         const res = await invoicesQuerier(orgData?.orgTimezone).fetchInvoiceRawData({ firestore }, fsOrgPrefix, { id: state.selectedRow });
//         const payload = ConfigureDuplicateInvoice(res, userData, nextInvoiceNumber);
//         await updateOrgDocument({
//           data: {
//             payload: { currentInvoiceCount: nextInvoiceNumber },
//             id: orgData?.id,
//           },
//         });
//         await newDocument({ data: { collection: INVOICES_COLLECTION, payload } });
//         await sleep(2000);
//         dispatch({ type: "SET_LOADING", payload: false });
//         // Implement onDuplicateComplete logic here
//       } catch (err) {
//         console.error(err);
//         dispatch({ type: "SET_LOADING", payload: false });
//         notificationError("Something went wrong");
//       }
//     }
//   }, [state.selectedRow, orgData, userData, nextInvoiceNumber, firestore, fsOrgPrefix]);

//   const handleDelete = useCallback(() => {
//     dispatch({ type: "SET_CONTEXT_MENU", payload: null });
//     Modal.confirm({
//       icon: <WarningFilled style={{ color: COLOR_RED_0 }} />,
//       title: "Delete invoice?",
//       content: "This cannot be undone! ...", // Your existing content here
//       okType: "danger",
//       async onOk() {
//         try {
//           if (!fsOrgPrefix) {
//             return notificationError("Something went wrong", "Please try again later");
//           }
//           await firestore.collection("orgs").doc(orgData.id).collection("tableRowInvoices").doc(state.selectedRow).delete();
//           await deleteDocument({
//             data: {
//               collection: INVOICES_COLLECTION,
//               id: state.selectedRow,
//             },
//           });
//           refetchInvoices();
//           notificationConfirm("Invoice deleted", "");
//         } catch (err) {
//           console.error(err);
//           notificationError("Something went wrong", "Please try again later");
//         }
//       },
//     });
//   }, [state.selectedRow, fsOrgPrefix, firestore, orgData, deleteDocument, refetchInvoices]);

//   return {
//     state,
//     dispatch,
//     tableRowInvoices,
//     fetchingInvoices,
//     refetchInvoices,
//     handleDuplicate,
//     handleDelete,
//   };
// };

// const Invoices = ({ history }) => {
//   const { userData, fsOrgPrefix, orgData } = useSelector(authSelector);
//   const { nextInvoiceNumber } = useInvoiceNumberListener();

//   const firestore = useFirestore();

//   const {
//     state,
//     dispatch: localDispatch,
//     tableRowInvoices,
//     fetchingInvoices,
//     refetchInvoices,
//     handleDuplicate,
//     handleDelete,
//   } = useInvoicesLogic(firestore, fsOrgPrefix, orgData, userData, nextInvoiceNumber);

//   const handleSelectionModelChange = useCallback(
//     (params) => {
//       localDispatch({ type: "SET_SELECTED_ROWS", payload: params });
//     },
//     [localDispatch]
//   );

//   const handleDatesChange = useCallback(
//     (dates) => {
//       localDispatch({ type: "SET_CURRENT_DATES", payload: dates });
//     },
//     [localDispatch]
//   );

//   useEffect(() => {
//     if (orgData && firestore) {
//       refetchInvoices();
//     }
//   }, [orgData, state.currentDates, firestore, refetchInvoices]);

//   const apiRef = useGridApiRef();

//   const handleChatClick = useCallback((invoice) => {
//     localDispatch({ type: "SET_CHAT_DRAWER", payload: { visible: true, invoice } });
//   }, []);

//   const handleEmailsClick = useCallback((invoice) => {
//     localDispatch({ type: "SET_EMAIL_DRAWER", payload: { visible: true, invoice } });
//   }, []);

//   const muiInvoiceTableColumns = useMemo(() => getColumns(orgData, handleEmailsClick, handleChatClick), [orgData, handleEmailsClick, handleChatClick]);

//   const handleView = useCallback(() => {
//     history.push(`${ROUTE_EDIT_INVOICE}${state.selectedRow}`);
//   }, [history, state.selectedRow]);

//   const handleDoubleClickMenu = useCallback((params, event) => {
//     localDispatch({ type: "SET_SELECTED_ROW", payload: params.id });
//     localDispatch({ type: "SET_CONTEXT_MENU", payload: { mouseX: event.clientX - 2, mouseY: event.clientY - 4 } });
//   }, []);

//   const handleContextMenu = useCallback((event) => {
//     event.preventDefault();
//     localDispatch({ type: "SET_SELECTED_ROW", payload: event.currentTarget.getAttribute("data-id") });
//     localDispatch({ type: "SET_CONTEXT_MENU", payload: { mouseX: event.clientX - 2, mouseY: event.clientY - 4 } });
//   }, []);

//   const handleClose = useCallback(() => {
//     localDispatch({ type: "SET_CONTEXT_MENU", payload: null });
//   }, []);

//   const saveStateToLocalStorage = useMemo(
//     () =>
//       debounce((state) => {
//         localStorage.setItem("CURRENT_GRID_SETTINGS", JSON.stringify(state));
//       }, 500),
//     []
//   );

//   useEffect(() => {
//     if (!orgData || !firestore) return;
//     refetchInvoices();
//   }, [orgData, firestore, refetchInvoices]);

//   return (
//     <div>
//       <Affix>
//         <PageHeader
//           className="PageHeader"
//           title={i18n("invoices.sales")}
//           extra={[
//             <div>
//               <Space size={12} className="make-the-sale-header">
//                 {state.selectedRows.length > 0 && (
//                   <Tooltip
//                     title={
//                       !checkSubscriptionLevel("makeTheSale.emailTemplates", orgData)
//                         ? "You do not have the required plan for sending email templates. Requires Plus Plan, you can change this in Admin/Billing"
//                         : ""
//                     }
//                   >
//                     <Button
//                       disabled={!checkSubscriptionLevel("makeTheSale.emailTemplates", orgData)}
//                       type="primary"
//                       onClick={() => localDispatch({ type: "SET_OPEN_TEMPLATE_DIALOG", payload: true })}
//                     >
//                       Send Emails
//                     </Button>
//                   </Tooltip>
//                 )}

//                 <Button
//                   type="primary"
//                   onClick={() => {
//                     history.push(ROUTE_CREATE_INVOICE);
//                   }}
//                 >
//                   {i18n("invoices.newOrder")}
//                 </Button>
//               </Space>
//             </div>,
//           ]}
//         />
//       </Affix>

//       {fetchingInvoices || state.loading ? (
//         <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", mt: 20 }}>
//           <CircularProgress />
//         </Box>
//       ) : (
//         <div className="make-the-sale-invoices">
//           <div style={{ width: "100%", paddingLeft: "8px", paddingRight: "8px" }}>
//             {orgData && apiRef && tableRowInvoices && (
//               <Box sx={{ height: "100%", width: "100%", backgroundColor: "white" }}>
//                 <DataGridPro
//                   disableRowSelectionOnClick
//                   style={{ paddingLeft: "8px" }}
//                   autoHeight={true}
//                   ignoreDiacritics={true}
//                   apiRef={apiRef}
//                   pagination={true}
//                   loading={fetchingInvoices || state.loading}
//                   columns={muiInvoiceTableColumns}
//                   onRowSelectionModelChange={handleSelectionModelChange}
//                   initialState={{
//                     pagination: {
//                       paginationModel: { pageSize: 25 },
//                     },
//                     sorting: {
//                       sortModel: [{ field: "invoiceNumber", sort: "desc" }],
//                     },
//                   }}
//                   slots={{
//                     noResultsOverlay: () => (
//                       <div
//                       style={{
//                         display: "flex",
//                         justifyContent: "center",
//                         alignItems: "center",
//                         height: "100%",
//                       }}
//                       >
//                         <h2>No results found. Try expanding the date range if you are not finding what you expect.</h2>
//                       </div>
//                     ),
//                     toolbar: () => CustomToolbar(apiRef, state.currentDates, handleDatesChange, orgData),
//                   }}
//                   onStateChange={(state) => {
//                     saveStateToLocalStorage(apiRef.current.exportState());
//                   }}
//                   rows={tableRowInvoices}
//                   onRowDoubleClick={(params, event) => handleDoubleClickMenu(params, event)}
//                   slotProps={{
//                     row: {
//                       onContextMenu: handleContextMenu,
//                       style: { cursor: "context-menu" },
//                     },
//                   }}
//                   checkboxSelection={true}
//                 />
//               </Box>
//             )}

//             <Menu
//               open={state.contextMenu !== null}
//               onClose={handleClose}
//               anchorReference="anchorPosition"
//               anchorPosition={state.contextMenu !== null ? { top: state.contextMenu.mouseY, left: state.contextMenu.mouseX } : undefined}
//               slotProps={{
//                 root: {
//                   onContextMenu: (e) => {
//                     e.preventDefault();
//                     handleClose();
//                   },
//                 },
//               }}
//             >
//               <MenuItem onClick={handleView}>View</MenuItem>
//               <MenuItem onClick={handleDuplicate}>Duplicate</MenuItem>
//               {/* <MenuItem onClick={handleReceivePayment}>Receive Payment</MenuItem> */}
//               <MenuItem onClick={handleDelete}>Delete</MenuItem>
//             </Menu>

//             <Dialog onClose={() => localDispatch({ type: "SET_OPEN_TEMPLATE_DIALOG", payload: false })} open={state.openTemplateDialog}>
//               <SendEmailTemplateView
//                 orgData={orgData}
//                 userData={userData}
//                 invoiceIds={state.selectedRows}
//                 close={() => localDispatch({ type: "SET_OPEN_TEMPLATE_DIALOG", payload: false })}
//                 complete={() => {
//                   enqueueSnackbar("Sending Emails. Results will be sent to Dashboard notifications", {
//                     variant: "success",
//                   });
//                   localDispatch({ type: "SET_OPEN_TEMPLATE_DIALOG", payload: false });
//                 }}
//               />
//             </Dialog>

//             {orgData && state.chatDrawer.visible && (
//               <Suspense fallback={<div>Loading...</div>}>
//                 <InternalNotesDrawer
//                   showTags={false}
//                   drawerView
//                   visible={state.chatDrawer.visible}
//                   handleCloseDrawer={() => localDispatch({ type: "SET_CHAT_DRAWER", payload: { visible: false, invoice: null } })}
//                   invoice={state.chatDrawer.invoice}
//                   tags={orgData.tags}
//                   onComplete={() => {
//                     localDispatch({ type: "SET_CHAT_DRAWER", payload: { visible: false, invoice: null } });
//                   }}
//                 />
//               </Suspense>
//             )}

//             {orgData && state.emailDrawer.visible && (
//               <Suspense fallback={<div>Loading...</div>}>
//                 <InvoiceEmailDrawer
//                   drawerView
//                   visible={state.emailDrawer.visible}
//                   handleCloseDrawer={() => localDispatch({ type: "SET_EMAIL_DRAWER", payload: { visible: false, invoice: null } })}
//                   invoice={state.emailDrawer.invoice}
//                   onComplete={() => {
//                     localDispatch({ type: "SET_EMAIL_DRAWER", payload: { visible: false, invoice: null } });
//                   }}
//                 />
//               </Suspense>
//             )}
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default Invoices;

// function debounce(func, wait) {
//   let timeout;

//   return function executedFunction(...args) {
//     const later = () => {
//       clearTimeout(timeout);
//       func(...args);
//     };

//     clearTimeout(timeout);
//     timeout = setTimeout(later, wait);
//   };
// }
